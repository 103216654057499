<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
// import Swal from "sweetalert2"
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Add User",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Add User",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "User",
                    href: "/master/user",
                },
                {
                    text: "Add User",
                    active: true,
                },
            ],
            axiosCatchError: null,
            // variable Page
            master_role: [],
            master_division: [],
            master_department: [],

            cwid: null,
            name: null,
            phone: null,
            email: null,
            role_selected: [],
            department_selected: [],
            division_selected: [],
            password: null,
            password_konfirmasi: null,
            showPassword: false,
            showPasswordConfirm: false,
        };
    },
    mounted() {
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        // Select Role
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-role",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then((response) => {
                this.master_role = response.data.data.referensi;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });

        // Select Division
        var configDivision = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-division",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(configDivision)
            .then((response) => {
                this.master_division = response.data.data.referensi;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });

        // Select Department
        var configDepartment = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-department",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(configDepartment)
            .then((response) => {
                this.master_department = response.data.data.referensi;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
                    {
                        cwid: self.cwid,
                        name: self.name,
                        email: self.email,
                        role_id: self.role_selected,
                        division_id: self.division_selected?.mdiv_id,
                        department_id: self.department_selected?.mdep_id,
                        password: self.password,
                        password_konfirmasi: self.password_konfirmasi,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "You will direct to master user page soon",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-user" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        cekcwid() {
            alert(123);
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShowConfirm() {
            this.showPasswordConfirm = !this.showPasswordConfirm;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3">
                                        <label for="formrow-cwid">CWID <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-cwid" type="text" v-model="cwid"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-name-input">Nama <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-name-input" type="text" v-model="name"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-email-input">Email <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-email-input" type="text" v-model="email"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="-role-input">Role <span class="text-danger">*</span></label>
                                        <v-select :options="master_role" label="role" v-model="role_selected" multiple></v-select>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-password-input">Password <span class="text-danger">*</span></label>
                                        <b-input-group prepend="">
                                            <b-form-input v-if="showPassword" id="formrow-password-input" type="text" v-model="password"></b-form-input>
                                            <b-form-input v-else id="formrow-password-input" type="password" v-model="password"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="toggleShow">
                                                    <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-password-input">Confirm Password <span class="text-danger">*</span></label>
                                        <b-input-group prepend="">
                                            <b-form-input v-if="showPasswordConfirm" id="formrow-password-confirm-input" type="text" v-model="password_konfirmasi"></b-form-input>
                                            <b-form-input v-else id="formrow-password-confirm-input" type="password" v-model="password_konfirmasi"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="toggleShowConfirm">
                                                    <i class="fas" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button variant="primary" type="submit"><i class="fa fa-save"></i> Save</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
